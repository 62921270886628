var config = [
    { value: '渠道匹配规则', key: '1' },
    { value: '结算比例规则', key: '2' },
    { value: '结算公司匹配规则', key: '3' },
];

export function getValue(key) {
    var item = config.filter((item) => item.key === key)[0];

    if (!item) {
        return '';
    }

    return item.value;
}

export default config;
