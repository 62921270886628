//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ruleType from './data/ruleType';

export default {
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {},
    data: function () {
        return {
            form: {
                ruleId: '',
                ruleName: '',
                ruleType: '',
                ruleContent: '',
                isEnabled: false,
            },

            ruleType,
        };
    },
    methods: {
        cancle: function (val) {
            this.$emit('close', val);
        },
        submit: function () {
            this.$post(
                `${this.$store.getters.indexUrl}/settle/settRule/save`,
                this.form,
                () => {
                    showMsg('添加成功', 'success');
                    this.cancle('submit');
                },
                true
            );
        },
        getDetail: function () {
            this.$get(
                `${this.$store.getters.indexUrl}/settle/settRule/getInfo`,
                {
                    ruleId: this.data.ruleId,
                },
                (data) => {
                    this.form = data;
                }
            );
        },
    },
    mounted() {
        if (this.data.ruleId) {
            this.getDetail();
        }
    },
};
