//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LIST_MIXIN from '@mixins/list-page';
import COMMON_METHODS from '@mixins/common-methods.js';
import FormPage from './form';
import ruleType from './data/ruleType';
import {getValue as getRuleValueByKey} from './data/ruleType';

export default {
    mixins: [LIST_MIXIN, COMMON_METHODS],
    props: [],
    components: {
        FormPage,
    },
    data: function () {
        return {
            pgData: {
                ruleId: '',
                ruleName: '',
                ruleType: '',
            },
            tableData: [],

            dialogTitle: '',
            dialogVisible: false,

            row: {},

            ruleType,
        };
    },
    computed: {},
    watch: {},

    methods: {
        doSearch: function () {
            this.$refs.page.queryData();
        },

        reload: function () {
            this.$refs.page.reload();
        },

        addHandler() {
            this.row = {};
            this.dialogTitle = '添加规则';
            this.dialogVisible = true;
        },

        canalInviteDialogClose(val) {
            this.dialogVisible = false;
            if (val === 'submit') {
                this.doSearch();
            }

            if (val === 'edit') {
                this.reload();
            }
        },

        editHandler(row) {
            this.row = row;
            this.dialogTitle = '编辑规则';
            this.dialogVisible = true;
        },

        setEnabled(row, isEnabled = false) {
            this.$post(
                `${this.$store.getters.indexUrl}/settle/settRule/save`,
                {
                    ...row,
                    isEnabled,
                },
                () => {
                    showMsg('修改成功', 'success');

                    this.reload();
                },
                true
            );
        },
        getRuleValueByKey,
        reloadAllHandler() {
            this.$post(`${this.$store.getters.indexUrl}/settle/settRule/reloadAll`, () => {
                showMsg('重新加载所有成功', 'success');
            });
        },
    },
    mounted() {},
};
